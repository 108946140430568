/**
 * @name: 推荐旅游接口
 * @author: itmobai
 * @date: 2023-06-03 09:42
 * @description：推荐旅游接口
 * @update: 2023-06-03 09:42
 */
import {get, postJ} from "@/request";
import {IMarketTravel} from "@/apis/marketingManage/travel/types";

/**
 * 查询推荐旅游列表
 */
export const recommendtourQueryRecommendTourListApi = () => get<IMarketTravel[]>("/golf/recommendtour/queryRecommendTourList")
/**
 * 推荐热门旅游修改
 * @param data
 */
export const recommendContestModifyApi = (data: Partial<IMarketTravel>) => postJ("/golf/recommendtour/modify", data)
/**
 * 热门旅游批量删除
 * @param ids
 */
export const recommendContestBatchDeleteApi = (ids: string) => get("/golf/recommendtour/batchDelete", {ids})
/**
 * 商品上下架
 * @param id
 * @param status
 */
export const recommendContestUpAndDownShelfApi = (id: string, status: number) => postJ("/golf/recommendtour/upAndDownShelf", {id,status})
/**
 * 创建推荐旅游
 * @param data
 */
export const recommendContestCreateApi = (data: any) => postJ("/golf/recommendtour/create", data)

